import React from "react";
import { TextInput } from "@mantine/core";
import styles from "./resuables.module.css";

interface TextInputItem {
    placeholder?: string;
    label: string;
    required?: boolean;
    name?: string;
    value?: any;
    error?: any;
    disabled?: boolean;
    leftsection?: any;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function TextInputKT(props: TextInputItem) {
    return (
        <TextInput
            placeholder={props.placeholder}
            label={props.label}
            name={props.name}
            value={props.value}
            error={props.error}
            required={props.required}
            disabled={props.disabled}
            onChange={props.onChange}
            leftSection={props.leftsection}
            classNames={{
                label: styles.textlabel,
                input: styles.textinput,
            }}
        />
    );
}
