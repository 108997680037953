import { experienceType } from "../../utils/experienceType";
import styles from "./header.module.css";

export default function ExperienceModal() {
    const experiences = experienceType.flatMap((category) => category.types);

    return (
        <section className={styles.activityModal}>
            {experiences.map((item) => (
                <figure
                    key={item.title}
                    onClick={() =>
                        (window.location.href = `/activity/${item.title}`)
                    }
                    className={styles.activitycard}
                >
                    <div className={styles.experienceicon}>{item.img} </div>
                    <p className={styles.name}>{item.title}</p>
                </figure>
            ))}
        </section>
    );
}
