import Instagram from "../icons/socials";
import { Tiktok, Whatsapp, Substack } from "../icons/socials";
import { mediumIconSize } from "../../utils/iconsize";
import styles from "./footer.module.css";

export default function Social() {
    return (
        <section className={styles.socialgroup}>
            <div
                onClick={() =>
                    window.open("https://www.instagram.com/kodamatravel/")
                }
            >
                <Instagram
                    width={mediumIconSize.width}
                    height={mediumIconSize.height}
                />
            </div>
            <div
                onClick={() =>
                    window.open("https://www.tiktok.com/@kodamatravel")
                }
            >
                <Tiktok
                    width={mediumIconSize.width}
                    height={mediumIconSize.height}
                />
            </div>
            <div onClick={() => window.open("https://wa.me/4407768804495")}>
                <Whatsapp
                    width={mediumIconSize.width}
                    height={mediumIconSize.height}
                />
            </div>

            <div
                onClick={() =>
                    window.open("https://substack.com/@kodamatravel")
                }
            >
                <Substack
                    width={mediumIconSize.width}
                    height={mediumIconSize.height}
                />
            </div>
        </section>
    );
}
