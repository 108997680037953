"use client";
import { Modal, Text, Group } from "@mantine/core";
import { currencies } from "../../utils/currency";
import { TabButtonKT } from "../resuables/Button";
import { useState, useEffect } from "react";
import styles from "./header.module.css";

export default function CurrencySelector() {
    const [opened, setOpened] = useState(false);

    const [selectedCurrencyCode, setSelectedCurrencyCode] =
        useState<string>("GBP");

    const [selectedCurrencySign, setSelectedCurrencySign] =
        useState<string>("£");

    useEffect(() => {
        if (typeof window !== "undefined") {
            const storedCurrencyCode = localStorage.getItem("userCurrencyCode");
            const storedCurrencySign = localStorage.getItem("userCurrencySign");

            if (storedCurrencyCode) {
                setSelectedCurrencyCode(storedCurrencyCode);
            }
            if (storedCurrencySign) {
                setSelectedCurrencySign(storedCurrencySign);
            } else {
                setSelectedCurrencyCode("GBP");
                setSelectedCurrencySign("£");
            }
        }
    }, []);

    return (
        <>
            <Modal opened={opened} onClose={() => setOpened(false)}>
                <div className={styles.currencypopup}>
                    {currencies.map((currency) => (
                        <Group
                            key={currency.currency}
                            className={styles.currencylabel}
                            onClick={() => {
                                setSelectedCurrencyCode(currency.code);
                                setSelectedCurrencySign(currency.sign);
                                if (typeof window !== "undefined") {
                                    localStorage.setItem(
                                        "userCurrencyCode",
                                        currency.code
                                    );
                                    localStorage.setItem(
                                        "userCurrencySign",
                                        currency.sign
                                    );
                                    setOpened(false);
                                    window.location.reload();
                                }
                            }}
                        >
                            <Text className={styles.currencyText}>
                                {currency.code}
                            </Text>{" "}
                            <Text className={styles.currencyText} c="dimmed">
                                {currency.sign}
                            </Text>
                        </Group>
                    ))}
                </div>
            </Modal>
            <TabButtonKT onClick={() => setOpened(true)}>
                {selectedCurrencyCode}
            </TabButtonKT>
        </>
    );
}
