import axios from "axios";

export const getWishList = async (userEmail: string) => {
    const response = await axios.get(`/api/getWishlist?userEmail=${userEmail}`);
    return response.data.favouriteListingInfo;
};

export const getUserAccount = async (userEmail: string) => {
    const response = await axios.get(`/api/account?userEmail=${userEmail}`);
    return response.data.account;
};

export const getUserBooking = async (userEmail: string) => {
    const response = await axios.get(`/api/userBooking?userEmail=${userEmail}`);
    return response.data.userBooking;
};

export const getSupplierListing = async (userEmail: string) => {
    const response = await axios.get(`/api/vendor/listing/${userEmail}`);
    return response.data.vendorListing;
};

export const getSupplierAccount = async (userEmail: string) => {
    const response = await axios.get(
        `/api/supplierAccount?userEmail=${userEmail}`
    );
    return response.data.vendorAccount;
};

export const getUserReward = async (userEmail: string) => {
    const response = await axios.get(
        `/api/rewardDashboard?userEmail=${userEmail}`
    );
    return response.data.rewardData;
};

export const getAdminData = async () => {
    const response = await axios.get(`/api/adminDashboard`);
    return response.data.adminData;
};
