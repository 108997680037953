"use client";
import React, { useState } from "react";
import { experienceType } from "../../utils/experienceType";
import { Tabs, Text } from "@mantine/core";
import styles from "./header.module.css";

export default function ExperienceTab() {
    const [experienceCat, setExperienceCat] = useState<string | null>(
        "Outdoors"
    );

    function handleActivityValue(type: string) {
        setExperienceCat(type);
    }

    return (
        <Tabs
            defaultValue="Outdoors"
            orientation="vertical"
            color="#4d3594"
            w={570}
        >
            <Tabs.List>
                {experienceType.map((item, index) => (
                    <Tabs.Tab
                        key={index}
                        onClick={() => handleActivityValue(item.category)}
                        value={item.category}
                    >
                        <Text className={styles.countrylist}>
                            {item.category}
                        </Text>
                    </Tabs.Tab>
                ))}
            </Tabs.List>

            <Tabs.Panel value={experienceCat || "Outdoors"}>
                {experienceType
                    .filter(
                        (tour) =>
                            tour.category.toLocaleLowerCase() ===
                            experienceCat?.toLocaleLowerCase()
                    )
                    .map((tour, index) => (
                        <section key={index} className={styles.tabgroup}>
                            {tour.types
                                .sort((a, b) => a.title.localeCompare(b.title))
                                .map((tour) => (
                                    <figure
                                        key={tour.title}
                                        className={styles.experiencecard}
                                        onClick={() =>
                                            (window.location.href = `/activity/${encodeURIComponent(
                                                tour.title
                                            )}`)
                                        }
                                    >
                                        <div className={styles.experienceicon}>
                                            {tour.img}
                                        </div>

                                        <Text className={styles.name}>
                                            {tour.title}
                                        </Text>
                                    </figure>
                                ))}
                        </section>
                    ))}
            </Tabs.Panel>
        </Tabs>
    );
}
