"use client";
import React, { useState } from "react";
import Image from "next/image";
import { regionCountry } from "../../utils/geographies";
import { Tabs, Text } from "@mantine/core";
import { useRouter } from "next/navigation";
import styles from "./header.module.css";

export default function DestinationTab() {
    const [region, setRegion] = useState<string | null>("asia");
    const router = useRouter();

    function handleRegionValue(region: string) {
        setRegion(region);
    }

    return (
        <Tabs
            defaultValue="asia"
            orientation="vertical"
            color="#4d3594"
            w={570}
        >
            <Tabs.List>
                {regionCountry.map((item, index) => (
                    <Tabs.Tab
                        key={index}
                        onClick={() => handleRegionValue(item.regionName)}
                        value={item.regionName}
                    >
                        <Text className={styles.countrylist}>
                            {item.regionName}
                        </Text>
                    </Tabs.Tab>
                ))}
            </Tabs.List>

            <Tabs.Panel value={region || "asia"}>
                {regionCountry
                    .filter(
                        (country) =>
                            country.regionName.toLocaleLowerCase() ===
                            region?.toLocaleLowerCase()
                    )
                    .map((region) => (
                        <section
                            key={region.regionName}
                            className={styles.tabgroup}
                        >
                            {region.countries
                                .sort((a, b) =>
                                    a.countryName.localeCompare(b.countryName)
                                )
                                .map((country) => (
                                    <figure
                                        key={country.countryName}
                                        className={styles.tabcard}
                                        onClick={() =>
                                            router.push(
                                                `/country/${encodeURIComponent(
                                                    country.countryName
                                                )}`
                                            )
                                        }
                                    >
                                        <Image
                                            src={country.img}
                                            alt={country.countryName}
                                            width={30}
                                            height={30}
                                            className={styles.icon}
                                            unoptimized
                                        />
                                        <Text className={styles.name}>
                                            {country.countryName}
                                        </Text>
                                    </figure>
                                ))}
                        </section>
                    ))}
            </Tabs.Panel>
        </Tabs>
    );
}
