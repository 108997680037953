export const currencies = [
    { currency: "British Pound Sterling", sign: "£", code: "GBP" },
    { currency: "US Dollar", sign: "$", code: "USD" },
    { currency: "Euro", sign: "€", code: "EUR" },
    { currency: "Argentina Peso", sign: "AR$", code: "ARS" },
    { currency: "Australian Dollar", sign: "A$", code: "AUD" },
    { currency: "Brazil Real", sign: "R$", code: "BRL" },
    { currency: "Canada Dollar", sign: "C$", code: "CAD" },
    { currency: "Canadian Dollar", sign: "C$", code: "CAD" },
    { currency: "Chile Peso", sign: "CLP$", code: "CLP" },
    { currency: "Chinese Yuan", sign: "¥", code: "CNY" },
    { currency: "Colombia Peso", sign: "COL$", code: "COP" },
    { currency: "Costa Rican Colón", sign: "₡", code: "CRC" },
    { currency: "Denmark Krone", sign: "kr", code: "DKK" },
    { currency: "Hong Kong Dollar", sign: "HK$", code: "HKD" },
    { currency: "Iceland Krona", sign: "kr", code: "ISK" },
    { currency: "India Rupee", sign: "₹", code: "INR" },
    { currency: "Japanese Yen", sign: "¥", code: "JPY" },
    { currency: "Kenyan Shilling", sign: "KSh", code: "KES" },
    { currency: "Malaysia Ringgit", sign: "RM", code: "MYR" },
    { currency: "Mexico Peso", sign: "MX$", code: "MXN" },
    { currency: "New Zealand Dollar", sign: "NZ$", code: "NZD" },
    { currency: "Norway Krone", sign: "kr", code: "NOK" },
    { currency: "Peru Sol", sign: "S/.", code: "PEN" },
    { currency: "Poland Zloty", sign: "zł", code: "PLN" },
    { currency: "Romania Leu", sign: "lei", code: "RON" },
    { currency: "Singapore Dollar", sign: "S$", code: "SGD" },
    { currency: "South Korean Won", sign: "₩", code: "KRW" },
    { currency: "Sweden Krona", sign: "kr", code: "SEK" },
    { currency: "Switzerland Franc", sign: "CHF", code: "CHF" },
    { currency: "Taiwan Dollar", sign: "NT$", code: "TWD" },
    { currency: "Thai Baht", sign: "฿", code: "THB" },
    { currency: "Turkey Lira", sign: "₺", code: "TRY" },
    { currency: "UAE Dirham", sign: "د.إ", code: "AED" },
    { currency: "Ukraine Hryvnia", sign: "₴", code: "UAH" },
];
