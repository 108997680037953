export const miniIconSize = {
    width: 16,
    height: 16,
    size: 3,
};

export const smallIconSize = {
    width: 20,
    height: 20,
    size: 4,
};

export const mediumIconSize = {
    width: 25,
    height: 25,
    size: 5,
};

export const experienceIconSize = {
    width: 30,
    height: 30,
};
