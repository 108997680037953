"use client";
import React, { useState } from "react";
import Image from "next/image";
import { regionMap, regionCountry } from "../../utils/geographies";
import styles from "./header.module.css";

export default function DestinationModal() {
    const [region, setRegion] = useState<string | null>(null);
    const [countryOpen, setCountryOpen] = useState(false);

    function handleRegionValue(region: string) {
        setRegion(region);
        setCountryOpen(true);
    }
    return (
        <>
            {!countryOpen ? (
                <section className={styles.modalgroup}>
                    {regionMap.map((item, index) => (
                        <figure
                            key={index}
                            onClick={() => handleRegionValue(item.title)}
                            className={styles.regioncard}
                        >
                            <Image
                                width={80}
                                height={80}
                                alt={item.title}
                                src={item.img}
                                unoptimized
                                className={styles.regionmap}
                            />
                            <p className={styles.name}>{item.title}</p>
                        </figure>
                    ))}
                </section>
            ) : (
                <>
                    {regionCountry
                        .filter(
                            (country) =>
                                country.regionName.toLocaleLowerCase() ===
                                region?.toLocaleLowerCase()
                        )
                        .map((region, index) => (
                            <section key={index} className={styles.modalgroup}>
                                {region.countries
                                    .sort((a, b) =>
                                        a.countryName.localeCompare(
                                            b.countryName
                                        )
                                    )
                                    .map((country) => (
                                        <figure
                                            key={country.countryName}
                                            className={styles.card}
                                            onClick={() =>
                                                (window.location.href = `/country/${encodeURIComponent(
                                                    country.countryName
                                                )}`)
                                            }
                                        >
                                            <Image
                                                src={country.img}
                                                alt={country.countryName}
                                                width={50}
                                                height={30}
                                                className={styles.icon}
                                            />
                                            <p className={styles.name}>
                                                {country.countryName}
                                            </p>
                                        </figure>
                                    ))}
                            </section>
                        ))}
                </>
            )}
        </>
    );
}
