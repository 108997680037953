import GoogleGeoSearch from "./GoogleGeoSearch";
import FeatureCity from "../country/FeaturedCity";
import styles from "./search.module.css";

export default function SearchModal() {
    return (
        <section className={styles.searchmodal}>
            <GoogleGeoSearch />
            <h3 className={styles.featuretitle}>Featured destinations</h3>
            <FeatureCity />
        </section>
    );
}
