import Bike from "../components/icons/experienceTypeIcon";
import {
    Hiking,
    Art,
    Aurora,
    Beach,
    Bird,
    Conservation,
    Cooking,
    Culture,
    Food,
    Kayak,
    Landmark,
    Landscape,
    Line,
    Multiday,
    Private,
    Safari,
    Ski,
    Snorkel,
    Surf,
    Whale,
    Scuba,
    Walkingtour,
    Climb,
    Rafting,
    Paddleboard,
    Coasteering,
    Bioluminescence,
    Stargazing,
    Outdoor,
} from "../components/icons/experienceTypeIcon";
export const experienceType = [
    {
        category: "Outdoors",
        types: [
            {
                title: "Hiking",
                img: <Hiking />,
            },
            {
                title: "Cycling",
                img: <Bike />,
            },
            {
                title: "Climbing",
                img: <Climb />,
            },

            {
                title: "Other outdoor",
                img: <Outdoor />,
            },
        ],
    },
    {
        category: "Wildlife",
        types: [
            {
                title: "Wildlife Safari",
                img: <Safari />,
            },
            {
                title: "Whale watching",
                img: <Whale />,
            },
            {
                title: "Bird Watching",
                img: <Bird />,
            },
            {
                title: "Conservation",
                img: <Conservation />,
            },
            {
                title: "Bioluminescence",
                img: <Bioluminescence />,
            },
        ],
    },
    {
        category: "Culinary",
        types: [
            {
                title: "Food & drink",
                img: <Food />,
            },
            {
                title: "Cooking class",
                img: <Cooking />,
            },
        ],
    },
    {
        category: "City Breaks",
        types: [
            {
                title: "Art",
                img: <Art />,
            },
            {
                title: "Cultural",
                img: <Culture />,
            },
            {
                title: "Landmarks",
                img: <Landmark />,
            },

            {
                title: "Skip the line",
                img: <Line />,
            },
            {
                title: "Walking tour",
                img: <Walkingtour />,
            },
        ],
    },
    {
        category: "Nature",
        types: [
            {
                title: "Northern lights",
                img: <Aurora />,
            },
            {
                title: "Landscape",
                img: <Landscape />,
            },
            {
                title: "Stargazing",
                img: <Stargazing />,
            },
        ],
    },
    {
        category: "Water Sports",
        types: [
            {
                title: "Scuba diving",
                img: <Scuba />,
            },

            {
                title: "Snorkelling",
                img: <Snorkel />,
            },
            {
                title: "Kayaking",
                img: <Kayak />,
            },
            {
                title: "Surfing",
                img: <Surf />,
            },
            {
                title: "Sun & beach",
                img: <Beach />,
            },
            {
                title: "Rafting",
                img: <Rafting />,
            },
            {
                title: "Coasteering",
                img: <Coasteering />,
            },
            {
                title: "Paddleboarding",
                img: <Paddleboard />,
            },
        ],
    },
    {
        category: "Winter Sports",
        types: [
            {
                title: "Skiing",
                img: <Ski />,
            },
        ],
    },

    {
        category: "Tour Type",
        types: [
            {
                title: "Private tour",
                img: <Private />,
            },

            {
                title: "Multi-day trip",
                img: <Multiday />,
            },
        ],
    },
];

export const tourCollection = [
    { title: "Africa", img: "../image/map/Africa.svg", slug: "africa" },
];
