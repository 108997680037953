import axios from "axios";

export const getCountryInfo = async (country: string) => {
    const response = await axios.get(
        `/api/country/${encodeURIComponent(country)}`
    );
    return response.data.countryInfo;
};

export const getCountryListing = async (country: string) => {
    const response = await axios.get(
        `/api/country/${encodeURIComponent(country)}`
    );
    return response.data.countryListing;
};

export const getCityCard = async (country: string) => {
    const response = await axios.get(
        `/api/country/${encodeURIComponent(country)}`
    );
    return response.data.cityCard;
};

export const getCityListing = async (country: string, city: string) => {
    const response = await axios.get(
        `/api/country/${encodeURIComponent(country)}/${encodeURIComponent(
            city
        )}`
    );
    return response.data.cityListing;
};

export const getCityHero = async (country: string, city: string) => {
    const response = await axios.get(
        `/api/country/${encodeURIComponent(country)}/${encodeURIComponent(
            city
        )}`
    );
    return response.data.cityInfo;
};

export const getFeaturedCity = async () => {
    const response = await axios.get(`/api/featureCity`);
    return response.data.cityList;
};
