interface iconSize {
    size: number;
    width: number;
    height: number;
}
export default function ChevUp(props: iconSize) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            width={props.width}
            height={props.height}
            className={`size-${props.size}`}
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m4.5 15.75 7.5-7.5 7.5 7.5"
            />
        </svg>
    );
}
