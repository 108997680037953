"use client";
import { useQuery } from "@tanstack/react-query";
import { getFeaturedCity } from "../../src/app/api/placeFunction";
import LoaderKT from "../resuables/Loader";
import { Card, Overlay } from "@mantine/core";
import styles from "./country.module.css";

interface cityItem {
    display_name: string;
    card_img: string;
    country: string;
    place_group: string;
}
export default function FeatureCity() {
    const { isLoading, isError, data } = useQuery({
        queryKey: ["cities"],
        queryFn: getFeaturedCity,
    });

    if (isLoading) return <LoaderKT />;
    if (isError) return null;

    return (
        <section className={styles.featurecitygroup}>
            {data.map((city: cityItem) => (
                <Card
                    key={city.place_group}
                    style={{ backgroundImage: `url(${city.card_img})` }}
                    className={styles.citycardfeature}
                    onClick={() =>
                        (window.location.href = `/country/${encodeURIComponent(
                            city.country
                        )}/${encodeURIComponent(city.place_group)}`)
                    }
                >
                    <Overlay
                        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .25) 90%)"
                        opacity={1}
                        zIndex={0}
                    />
                    <h4 className={styles.featurecardtitle}>
                        {city.display_name}
                    </h4>
                </Card>
            ))}
        </section>
    );
}
