"use client";
import { useMediaQuery } from "@mantine/hooks";
import Socials from "./Socials";
import Menu from "./Menu";
import Disclaimar from "./Disclaimer";
import MobileMenu from "./MobileMenu";
import styles from "./footer.module.css";

export default function Footer() {
    const isBigscreen = useMediaQuery("(min-width: 768px)");

    return (
        <>
            {isBigscreen ? (
                <footer className={styles.container}>
                    <Socials />
                    <Menu />
                    <Disclaimar />
                </footer>
            ) : (
                <footer className={styles.container}>
                    <Socials />
                    <MobileMenu />
                    <Disclaimar />
                </footer>
            )}
        </>
    );
}
