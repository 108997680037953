import styles from "./footer.module.css";

export default function Disclaimar() {
    const currentYear = new Date().getFullYear();
    return (
        <section className={styles.disclaimar}>
            <p className={styles.footertext}>
                Made with 💛 in London
                <br />© {currentYear} Kodama Travel. All rights reserved.
                Registration number: 15352750 (England and Wales).
            </p>
        </section>
    );
}
