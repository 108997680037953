"use client";
import Moon from "../icons/moon";
import Sun from "../icons/sun";
import {
    ActionIcon,
    useMantineColorScheme,
    useComputedColorScheme,
} from "@mantine/core";
import { miniIconSize } from "../../utils/iconsize";

export default function ToggleTheme() {
    const { setColorScheme } = useMantineColorScheme();
    const computedColorScheme = useComputedColorScheme("light", {
        getInitialValueInEffect: true,
    });

    return (
        <ActionIcon
            variant="default"
            radius="md"
            size="md"
            p="2px"
            aria-label="Toggle color scheme"
            onClick={() =>
                setColorScheme(
                    computedColorScheme === "light" ? "dark" : "light"
                )
            }
        >
            {computedColorScheme === "light" ? (
                <Moon
                    height={miniIconSize.width}
                    width={miniIconSize.height}
                    size={miniIconSize.size}
                />
            ) : (
                <Sun
                    height={miniIconSize.width}
                    width={miniIconSize.height}
                    size={miniIconSize.size}
                />
            )}
        </ActionIcon>
    );
}
